<template>
  <sign-page
    title-text="过期预警"
    :request="request"
    :title-menus="[{key: 'send_msg', label: '语音通知'}]"
    :table-actions="actions"
    :column-list="columnList"
    @clickTitleMenu="clickTitleMenu"
    table-size="large"
    :tableActionsFixed="true"
    :form-parms-add="formParmsAdd"
    :form-parms-update="formParmsUpdate"
    :table-actions-width="60"
    :need-data-file="true">
  </sign-page>
</template>

<script>
import {
  agreementRequest as request
} from '@/api'

export default {
  computed: {
    formParmsAdd: {
      get () {
        let data = [{
          type: 'input',
          label: '证件编号',
          key: 'code',
          check: {
            required: true
          }
        },
        {
          type: 'select',
          label: '证件类型',
          key: 'type',
          selectDatas: [{key: '劳动合同', label: '劳动合同'}, {key: '执业资格证', label: '执业资格证'}]
        },
        {
          type: 'input',
          label: '证件名称',
          key: 'title'
        },
        {
          type: 'datePicker',
          label: '生效日期',
          width: 130,
          key: 'startDate'
        },
        {
          type: 'datePicker',
          label: '失效日期',
          width: 130,
          key: 'endDate'
        },
        {
          type: 'select',
          label: '所属员工',
          key: 'workerId',
          selectDatas: this.$store.getters.workerList
        }]
        return data
      }
    },
    formParmsUpdate: {
      get () {
        let data = []
        this.formParmsAdd.forEach((item) => {
          let newItem = {}
          for (let key in item) {
            newItem[key] = item[key]
          }
          data.push(newItem)
        })
        return data
      }
    },
    actions () {
      return [{key: 'edit', label: '处理'}]
    },
    columnList: {
      get () {
        let data = [{
          title: '姓名',
          field: 'workerName',
          sort: true,
          width: 100
        },
        // {
        //   title: '科室',
        //   field: 'orgName',
        //   sort: true
        // },
        {
          title: '工号',
          field: 'workerCode',
          sort: true
        },
        {
          title: '预警内容',
          field: 'warningText',
          sort: true
        },
        {
          title: '证件（合同）编号',
          width: 150,
          field: 'code',
          sort: true
        },
        {
          title: '失效日期',
          field: 'endDate',
          sort: true,
          dataType: Date,
          render: (h, rowData) => {
            return h('div', rowData && rowData.endDate ? rowData.endDate.slice(0, 10) : '-')
          }
        },
        {
          title: '剩余天数',
          field: 'lastDays',
          width: 130,
          sort: true,
          dataType: Number,
          render: (h, rowData) => {
            return h('div', {
              domProps: {
                innerHTML: rowData.lastDays || '-'
              },
              style: {
                color: rowData.lastDays < 31 ? '#ed4014' : '#ff9900'
              }
            })
          }
        }]
        return data
      }
    }
  },
  created () {
    if (this.$authFunsProxy.edit) {
      this.$store.dispatch('loadWorkerList')
    }
  },
  methods: {
    async clickTitleMenu () {
      await request.sendWarning({})
      this.$notice.success({
        title: '系统提示',
        desc: '语言通知任务已启动'
      })
    }
  },
  data () {
    return {
      request: {
        get: async () => {
          let data = await request.get()
          let workerMap = {}
          data.forEach(v => {
            v.lastDays = 10000
            if (v.endDate) {
              let ms = new Date(v.endDate).getTime() - new Date().getTime()
              v.lastDays = parseInt(ms / 1000 / 60 / 60 / 24) + 1
            }
            if (!workerMap[v.workerId]) {
              workerMap[v.workerId] = [v]
            } else {
              workerMap[v.workerId].push(v)
            }
          })
          let datas = []
          Object.keys(workerMap).forEach((workerId) => {
            let needWarning = workerMap[workerId].filter(v => v.lastDays > 90).length === 0
            console.log(needWarning)
            if (needWarning) {
              workerMap[workerId].sort((a, b) => b.lastDays - a.lastDays)
              console.log(workerMap[workerId])
              workerMap[workerId][0].warningText = workerMap[workerId][0].type + (workerMap[workerId][0].lastDays > 0 ? '即将过期' : '已过期')
              datas.push(workerMap[workerId][0])
            }
          })
          return datas
        },
        update: request.update,
        add: request.add
      }
    }
  }
}
</script>
